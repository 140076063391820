import { REPORTS_TABS } from '@/enums/reports'
import { RESOURCE_TABS } from '@/enums/resources'
import { SERVICES_TABS } from '@/enums/services'
import { SETTINGS_TABS } from '@/enums/settings'
import { SUPPORTS_TABS } from '@/enums/supports'
import { TASKS_TABS } from '@/enums/tasks'
import i18n from '@/i18n'
import ProviderLayout from '@/layouts/ProviderLayout.vue'
import { ROUTE_PATHS } from '@/router/paths'
import publicRoutes from '@/router/public/routes'
import { useAuthStore } from '@/store/auth'
import { RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import { useToast } from 'vue-toastification'
import adminRoutes from './admin/routes'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: '/',
    redirect: ROUTE_PATHS.LOGIN,
    beforeEnter: [isLoggedOut],
  },
  {
    path: '/',
    name: '/',
    redirect: ROUTE_PATHS.LOGIN,
    beforeEnter: [isLoggedOut],
  },
  {
    path: ROUTE_PATHS.OVERVIEW,
    name: ROUTE_PATHS.OVERVIEW,
    // TODO: Temporary - Had to comment out component for redirect usage as well
    redirect: ROUTE_PATHS.CALENDAR,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import('@/views/OverviewView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.CALENDAR_OVERVIEW,
    name: ROUTE_PATHS.CALENDAR_OVERVIEW,
    component: () => import('@/views/OverviewView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.LOGIN,
    name: ROUTE_PATHS.LOGIN,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/LoginView.vue'),
    beforeEnter: [isLoggedOut],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.CHOOSE_COMPANY,
    name: ROUTE_PATHS.CHOOSE_COMPANY,
    component: () => import('@/views/ChooseCompanyView.vue'),
    beforeEnter: [isBusiness],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.REGISTER,
    name: ROUTE_PATHS.REGISTER,
    component: () => import('@/views/RegisterView.vue'),
    beforeEnter: [isLoggedOut],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.VERIFY_NEW_USER,
    name: ROUTE_PATHS.VERIFY_NEW_USER,
    component: () => import('@/views/ActivationView.vue'),
    beforeEnter: [isLoggedOut],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.RESET_PASSWORD,
    name: ROUTE_PATHS.RESET_PASSWORD,
    component: () => import('@/views/RequestPasswordResetView.vue'),
    beforeEnter: [isLoggedOut],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.SET_NEW_PASSWORD,
    name: ROUTE_PATHS.SET_NEW_PASSWORD,
    component: () => import('@/views/SetNewPasswordView.vue'),
    beforeEnter: [isLoggedOut],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.INVITED_USER,
    name: ROUTE_PATHS.INVITED_USER,
    component: () => import('@/views/InvitedUserView.vue'),
    beforeEnter: [isLoggedOut],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.CALENDAR,
    name: ROUTE_PATHS.CALENDAR,
    component: () => import('@/views/CalendarView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.BOOKING,
    name: ROUTE_PATHS.BOOKING,
    component: () => import('@/views/BookingView.vue'),
    props: true,
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.INVOICES,
    name: ROUTE_PATHS.INVOICES,
    component: () => import('@/views/public/PrivacyPolicy.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.TICKET_LIST,
    component: () => import('@/views/tickets/TicketsWrapperView.vue'),
    children: [
      {
        path: ROUTE_PATHS.TICKET_LIST,
        name: ROUTE_PATHS.TICKET_LIST,
        component: () => import('@/views/tickets/TicketListView.vue'),
        beforeEnter: [isBusiness, selectCompany, hasReadTicketsPermission],
        meta: { layout: ProviderLayout },
      },
      {
        path: ROUTE_PATHS.TICKET_LIST_VALIDATION,
        name: ROUTE_PATHS.TICKET_LIST_VALIDATION,
        component: () => import('@/views/tickets/TicketListValidationView.vue'),
        beforeEnter: [isBusiness, selectCompany, hasValidateTicketsPermission],
        meta: { layout: ProviderLayout },
      },
    ],
  },
  {
    path: ROUTE_PATHS.TICKET_VALIDATION,
    name: ROUTE_PATHS.TICKET_VALIDATION,
    component: () => import('@/views/tickets/TicketValidationView.vue'),
    meta: { layout: ProviderLayout },
    props: (route: RouteLocationNormalized) => {
      let validate: boolean | undefined = undefined
      if (route.query?.validate === 'true') {
        validate = true
      } else if (route.query?.validate === 'false') {
        validate = false
      }
      return {
        uuid: route.params?.uuid,
        validate,
      }
    },
  },
  {
    path: ROUTE_PATHS.CLIENTS,
    name: ROUTE_PATHS.CLIENTS,
    component: () => import('@/views/ClientsView.vue'),
    props: true,
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.CLIENT,
    name: ROUTE_PATHS.CLIENT,
    component: () => import('@/views/ClientView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.TASKS,
    name: ROUTE_PATHS.TASKS,
    component: () => import('@/views/TasksTabView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: TASKS_TABS.TASKS },
  },
  {
    path: ROUTE_PATHS.TASK,
    name: ROUTE_PATHS.TASK,
    component: () => import('@/views/TaskView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.SERVICES,
    name: ROUTE_PATHS.SERVICES,
    component: () => import('@/views/ServicesTabView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SERVICES_TABS.SERVICES },
  },
  {
    path: ROUTE_PATHS.SERVICE,
    name: ROUTE_PATHS.SERVICE,
    component: () => import('@/views/ServiceView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.ADDITIONAL_SERVICES,
    name: ROUTE_PATHS.ADDITIONAL_SERVICES,
    component: () => import('@/views/ServicesTabView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SERVICES_TABS.ADDITIONAL_SERVICES },
  },
  {
    path: ROUTE_PATHS.ADDITIONAL_SERVICE,
    name: ROUTE_PATHS.ADDITIONAL_SERVICE,
    component: () => import('@/views/AdditionalServiceView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.RESOURCES,
    name: ROUTE_PATHS.RESOURCES,
    component: () => import('@/views/ResourcesTabView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: RESOURCE_TABS.RESOURCES },
  },
  {
    path: ROUTE_PATHS.RESOURCE_GROUPS,
    name: ROUTE_PATHS.RESOURCE_GROUPS,
    component: () => import('@/views/ResourcesTabView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: RESOURCE_TABS.RESOURCE_GROUPS },
  },
  {
    path: ROUTE_PATHS.RESOURCE,
    name: ROUTE_PATHS.RESOURCE,
    component: () => import('@/views/ResourceView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.BOOKKEEPING,
    name: ROUTE_PATHS.BOOKKEEPING,
    component: () => import('@/views/BookKeepingView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.REPORTS,
    name: ROUTE_PATHS.REPORTS,
    component: () => import('@/views/ReportsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.SALES_REPORT,
    name: ROUTE_PATHS.SALES_REPORT,
    component: () => import('@/views/ReportsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: REPORTS_TABS.SALES_REPORT },
  },
  {
    path: ROUTE_PATHS.DEPOSIT_REPORT,
    name: ROUTE_PATHS.DEPOSIT_REPORT,
    component: () => import('@/views/ReportsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: REPORTS_TABS.DEPOSIT_REPORT },
  },
  {
    path: ROUTE_PATHS.PARTNERS_REPORT,
    name: ROUTE_PATHS.PARTNERS_REPORT,
    component: () => import('@/views/ReportsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: REPORTS_TABS.PARTNERS_REPORT },
  },
  {
    path: ROUTE_PATHS.STATISTICS_REPORT,
    name: ROUTE_PATHS.STATISTICS_REPORT,
    component: () => import('@/views/ReportsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: REPORTS_TABS.STATISTICS_REPORT },
  },
  {
    // TODO: TOUR-118: Deprecated
    path: ROUTE_PATHS.CHANNEL_MANAGER,
    name: ROUTE_PATHS.CHANNEL_MANAGER,
    component: () => import('@/components/settings/SettingsIntegrations.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.CHANNEL_MANAGER_LIST,
    name: ROUTE_PATHS.CHANNEL_MANAGER_LIST,
    component: () =>
      import('@/views/channel-manager/PropertyChannelListView.vue'),
    beforeEnter: [isBusiness, selectCompany, hasAccessToChannels],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.CHANNEL_MANAGER_DETAILS,
    name: ROUTE_PATHS.CHANNEL_MANAGER_DETAILS,
    component: () =>
      import('@/views/channel-manager/PropertyChannelDetailsView.vue'),
    beforeEnter: [isBusiness, selectCompany, hasAccessToChannels],
    props: true,
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.SETTINGS,
    name: ROUTE_PATHS.SETTINGS,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.GENERAL },
  },
  {
    path: ROUTE_PATHS.SETTINGS_PAYMENT,
    name: ROUTE_PATHS.SETTINGS_PAYMENT,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.PAYMENT },
  },
  {
    path: ROUTE_PATHS.SETTINGS_GENERAL,
    name: ROUTE_PATHS.SETTINGS_GENERAL,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.GENERAL },
  },
  {
    path: ROUTE_PATHS.SETTINGS_PROFILE,
    name: ROUTE_PATHS.SETTINGS_PROFILE,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.PROFILE },
  },
  {
    path: ROUTE_PATHS.SETTINGS_LOCATION,
    name: ROUTE_PATHS.SETTINGS_LOCATION,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.LOCATION },
  },
  {
    path: ROUTE_PATHS.SETTINGS_USERS,
    name: ROUTE_PATHS.SETTINGS_USERS,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.USER_RIGHTS },
  },
  {
    path: ROUTE_PATHS.SETTINGS_NOTIFICATIONS,
    name: ROUTE_PATHS.SETTINGS_NOTIFICATIONS,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.EMAILS },
  },
  {
    path: ROUTE_PATHS.SETTINGS_EMAILS,
    name: ROUTE_PATHS.SETTINGS_EMAILS,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.EMAILS },
    children: [
      {
        path: ROUTE_PATHS.SETTINGS_NOTIFICATIONS,
        name: ROUTE_PATHS.SETTINGS_NOTIFICATIONS,
        component: () =>
          import('@/components/settings/emails/CompanyNotifications.vue'),
        beforeEnter: [isBusiness, selectCompany],
        meta: { layout: ProviderLayout, tab: SETTINGS_TABS.EMAILS },
      },
      {
        path: ROUTE_PATHS.SETTINGS_TEMPLATES,
        name: ROUTE_PATHS.SETTINGS_TEMPLATES,
        component: () =>
          import('@/components/settings/emails/SettingsTemplates.vue'),
        beforeEnter: [isBusiness, selectCompany],
        meta: { layout: ProviderLayout, tab: SETTINGS_TABS.EMAILS },
      },
    ],
  },
  {
    path: ROUTE_PATHS.SETTINGS_CHECKIN,
    name: ROUTE_PATHS.SETTINGS_CHECKIN,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.CHECKIN },
  },
  {
    path: ROUTE_PATHS.SETTINGS_CONTRACTS,
    name: ROUTE_PATHS.SETTINGS_CONTRACTS,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.CONTRACTS },
  },
  {
    path: ROUTE_PATHS.SETTINGS_INTEGRATIONS,
    name: ROUTE_PATHS.SETTINGS_INTEGRATIONS,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.INTEGRATIONS },
  },
  {
    path: ROUTE_PATHS.SETTINGS_GOOGLE_CALENDAR,
    name: ROUTE_PATHS.SETTINGS_GOOGLE_CALENDAR,
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.GOOGLE_CALENDAR },
  },
  {
    path: ROUTE_PATHS.SUPPORT,
    name: ROUTE_PATHS.SUPPORT,
    component: () => import('@/views/SupportView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.SUPPORT_CONTACT,
    name: ROUTE_PATHS.SUPPORT_CONTACT,
    component: () => import('@/views/SupportView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SUPPORTS_TABS.CONTACT },
  },
  {
    path: ROUTE_PATHS.SUPPORT_HELP,
    name: ROUTE_PATHS.SUPPORT_HELP,
    component: () => import('@/views/SupportView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SUPPORTS_TABS.HELP_CENTER },
  },
  {
    path: ROUTE_PATHS.STYLESHEET,
    name: ROUTE_PATHS.STYLESHEET,
    component: () => import('@/views/StylesheetView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.TERMS,
    name: ROUTE_PATHS.TERMS,
    component: () => import('@/views/public/TermsView.vue'),
    beforeEnter: [],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.PRIVACY_POLICY,
    name: ROUTE_PATHS.PRIVACY_POLICY,
    component: () => import('@/views/public/PrivacyPolicy.vue'),
    beforeEnter: [],
    meta: { layout: ProviderLayout },
  },
  {
    path: ROUTE_PATHS.PARTNER_COMPANY_LIST,
    name: 'PROVIDER_PARTNER_COMPANY_WRAPPER',
    component: () => import('@/views/SettingsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.PARTNERS },
    children: [
      {
        path: '',
        name: ROUTE_PATHS.PARTNER_COMPANY_LIST,
        component: () => import('@/views/partnership/PartnershipListView.vue'),
        beforeEnter: [isBusiness, selectCompany],
        meta: { layout: ProviderLayout, tab: SETTINGS_TABS.PARTNERS },
      },
      {
        path: 'invites',
        name: ROUTE_PATHS.PARTNER_COMPANY_INVITE_LIST,
        component: () =>
          import('@/views/partnership/PartnershipInviteListView.vue'),
        beforeEnter: [isBusiness, selectCompany],
        meta: { layout: ProviderLayout, tab: SETTINGS_TABS.PARTNERS },
      },
    ],
  },
  {
    path: ROUTE_PATHS.PARTNER_COMPANY_DETAILS,
    name: ROUTE_PATHS.PARTNER_COMPANY_DETAILS,
    component: () => import('@/views/partnership/PartnershipDetailsView.vue'),
    beforeEnter: [isBusiness, selectCompany],
    meta: { layout: ProviderLayout, tab: SETTINGS_TABS.PARTNERS },
    props: true,
  },
  {
    path: ROUTE_PATHS.PARTNER_COMPANY_INVITE_DETAILS,
    name: ROUTE_PATHS.PARTNER_COMPANY_INVITE_DETAILS,
    component: () =>
      import('@/views/partnership/PartnershipInviteDetailsPublicView.vue'),
    props: true,
    beforeEnter: [],
    meta: { layout: ProviderLayout },
  },
  ...adminRoutes,
  //Public one are last, because router parameter position
  ...publicRoutes,
]

export default routes

function isLoggedOut() {
  const authStore = useAuthStore()
  if (authStore.isAuthenticated) {
    return { path: ROUTE_PATHS.OVERVIEW }
  }
}

async function isBusiness(to: RouteLocationNormalized) {
  const authStore = useAuthStore()
  if (!authStore.isAuthenticated) {
    return {
      path: ROUTE_PATHS.LOGIN,
      query: {
        next: to.path,
      },
    }
  }

  // Logged in, but user data not fetched yet.
  if (authStore.user === null) {
    try {
      await authStore.getUser()
    } catch (e) {
      // this can throw 401 when auth token is already expired
      // we need to catch the error and return a valid path
      // otherwise users will see a blank screen until they refresh
      return {
        path: ROUTE_PATHS.LOGIN,
      }
    }
  }
}

function selectCompany(to: RouteLocationNormalized) {
  const authStore = useAuthStore()
  if (!authStore.selectedCompanyId) {
    return {
      path: ROUTE_PATHS.CHOOSE_COMPANY,
      query: {
        next: to.path,
      },
    }
  }
}

function hasAccessToChannels(to: RouteLocationNormalized) {
  const authStore = useAuthStore()
  if (!authStore.canViewPropertyChannel) {
    useToast().warning(
      i18n.global.t('noPermissionsForRouteAccessError', { route: to.path }),
    )
    return {
      path: ROUTE_PATHS.OVERVIEW,
    }
  }
}

function hasReadTicketsPermission(to: RouteLocationNormalized) {
  const authStore = useAuthStore()
  if (!authStore.canReadTickets) {
    useToast().warning(
      i18n.global.t('noPermissionsForRouteAccessError', { route: to.path }),
    )
    return {
      path: ROUTE_PATHS.OVERVIEW,
    }
  }
}

function hasValidateTicketsPermission(to: RouteLocationNormalized) {
  const authStore = useAuthStore()
  if (!authStore.canUpdateTickets) {
    useToast().warning(
      i18n.global.t('noPermissionsForRouteAccessError', { route: to.path }),
    )
    return {
      path: ROUTE_PATHS.OVERVIEW,
    }
  }
}
